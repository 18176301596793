import React, { createContext, useReducer, useContext, useEffect } from "react";

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("token", action.payload.token); // Store token
      localStorage.setItem("isGoogle", action.payload.isGoogle);
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        isGoogle: action.payload.isGoogle,
      };
    case "LOGOUT":
      localStorage.removeItem("token"); // Remove token
      localStorage.removeItem("isGoogle");
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
        isGoogle: null,
      };
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

export const AuthProvider = ({ children }) => {
  const initialState = {
    isAuthenticated: !!localStorage.getItem("token"), // Initial check for token presence
    user: null,
    token: localStorage.getItem("token"), // Retrieve token if present
    isGoogle: localStorage.getItem("isGoogle"),
  };
  const [state, dispatch] = useReducer(authReducer, initialState);

  // This effect updates the state when the app initially loads,
  // ensuring that isAuthenticated reflects the actual token presence.
  useEffect(() => {
    const token = localStorage.getItem("token");
    const isGoogle = localStorage.getItem("isGoogle");
    if (token) {
      dispatch({ type: "LOGIN", payload: { token, isGoogle } });
    }
  }, []);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export const useAuthState = () => useContext(AuthStateContext);
export const useAuthDispatch = () => useContext(AuthDispatchContext);
