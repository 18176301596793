import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./AuthContext"; // Adjust the import path as necessary
import "./i18n.js";
import { Helmet, HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      {/* Include the Plausible tracking script in the HelmetProvider context */}
      <Helmet>
        <script
          defer
          data-domain="dev.keepme.gr"
          src="https://plausible.io/js/script.file-downloads.outbound-links.pageview-props.tagged-events.js"
        ></script>
      </Helmet>
      <AuthProvider>
        {" "}
        <App />
      </AuthProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
